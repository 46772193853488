.compose-form {
  .compose-form__modifiers {
    .compose-form__upload {
      &-description {
        input {
          &::placeholder {
            opacity: 1;
          }
        }
      }
    }
  }
}

.status__content a,
.link-footer a,
.reply-indicator__content a,
.status__content__read-more-button {
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &.mention {
    text-decoration: none;

    span {
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      span {
        text-decoration: none;
      }
    }
  }
}

.status__content a {
  color: $highlight-text-color;
}

.nothing-here {
  color: $darker-text-color;
}

.compose-form__poll-wrapper .button.button-secondary,
.compose-form .autosuggest-textarea__textarea::placeholder,
.compose-form .spoiler-input__input::placeholder,
.report-dialog-modal__textarea::placeholder,
.language-dropdown__dropdown__results__item__common-name,
.compose-form .icon-button {
  color: $inverted-text-color;
}

.text-icon-button.active {
  color: $ui-highlight-color;
}

.language-dropdown__dropdown__results__item.active {
  background: $ui-highlight-color;
  font-weight: 500;
}

.link-button:disabled {
  cursor: not-allowed;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none !important;
  }
}
